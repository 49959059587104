import React, { Component } from 'react'

import Wrapper from '../Components/Wrapper'
import CountDown from '../Components/CountDown'
import Header from '../Components/Header'
import TimeOut from '../Components/TimeOut'
import Question from '../Components/Question'
import Congratz from '../Components/Congratz'

const add = (a, b) => a + b

class PublicQuiz extends Component {
  state = {
    currentQuestionIndex: 0,
    userAnswers: [],
    quizPaused: false,
  }

  toggleQuizStatus = () => {
    const { quizPaused, currentQuestionIndex } = this.state
    const newState = {
      currentQuestionIndex: quizPaused ? add(currentQuestionIndex, 1) : currentQuestionIndex,
      quizPaused: !quizPaused
    }
    this.setState({
      ...newState
    })
  }

  handleSubmitAnswer = answer => {
    const { currentQuestionIndex, userAnswers } = this.state
    this.setState({
      currentQuestionIndex: currentQuestionIndex + 1,
      userAnswers: [...userAnswers, answer]
    })
    // to enable buttons
    return userAnswers
  }

  render() {
    const {
      quiz: { questions, name, _id }
    } = this.props
    const { currentQuestionIndex, userAnswers, quizPaused } = this.state
    if (questions.length === currentQuestionIndex) {
      const fullScore = questions.reduce(
        (acc, question) => acc + question.score,
        0
      )
      const totalScore = questions
        .filter((question, i) => question.answer === userAnswers[i]) // filter correct answer
        .reduce((sum, question) => sum + question.score, 0) // calc total of correct answer
      const score = (totalScore * 100) / fullScore
      return (
        <div style={{ display: 'flex' }}>
          <Wrapper>
            <Congratz
              score={parseInt(score)}
              submittedAt={new Date()}
              quizType='anon'
              quizId={_id}
            />
          </Wrapper>
        </div>
      )
    }
    const timeLeft = questions[currentQuestionIndex].duration

    return (
      <Wrapper>
        <Header />
        {
          quizPaused
            ? <TimeOut onResumeQuiz={this.toggleQuizStatus} />
            : (
              <>
                <CountDown
                  duration={questions[currentQuestionIndex].duration}
                  timeLeft={timeLeft}
                  onQuestionTimeOut={this.toggleQuizStatus}
                />
                <Question
                  quizName={name}
                  questionNbr={currentQuestionIndex + 1}
                  totalQuestions={questions.length}
                  question={questions[currentQuestionIndex]}
                  onSubmit={this.handleSubmitAnswer}
                />
              </>
            )
        }
      </Wrapper>
    )
  }
}

export default PublicQuiz
