import React from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import styled from "styled-components";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/swift/swift";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/clike/clike";
import "codemirror/mode/php/php";
import "codemirror/mode/python/python";
import "codemirror/mode/ruby/ruby";
import "codemirror/mode/css/css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/go/go";
import "codemirror/mode/dart/dart";
import "codemirror/theme/base16-dark.css";

const StyleBase = styled.div`
	margin-top: 30px;
	.CodeMirror {
		height: auto;
	}
	.language-mode {
		font-size: 12px;
		font-family: sans-serif;
		background: #f7f7f7;
		color: #2d2d2d;
		padding: 5px 19px;
	}
`;
const getMode = (mode) => {
	switch (mode) {
		case "kotlin":
		case "java":
		case "objective-c":
		case "c":
		case "c++":
			return "clike";
		case "html":
			return "xml";
		default:
			return mode;
	}
};

const Editor = ({ mode, value }) => {
	return (
		<StyleBase>
			<div className="language-mode">Language: {mode}</div>
			<CodeMirror
				options={{
					mode: getMode(mode),
					theme: "base16-dark",
					lineNumbers: true,
					readOnly: true,
				}}
				value={value}
			/>
		</StyleBase>
	);
};

export default Editor;
