import styled, { css } from 'styled-components'

export const TopicScoresRow = styled.div`
  padding: 5% 0;
  width: 100%;

  h4 {
    margin: 0;
    font-weight: 500;
    color: black;
    margin-bottom: 5%;
  }
`

export const TopicsBody = styled.div`
  width: 100%;

  .metrics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      display: flex;
      align-items: center;
    }

    h4 {
      font-size: 14px;
      line-height: 24px;
      color: #909090;
      font-family: Roboto, sans-serif;
      margin: 0;
    }

    h3 {
      font-family: Roboto, sans-serif;
      line-height: 24px;
      color: black;
      font-weight: 500;
      font-size: 20px;
      margin: 0;
    }

    .tooltip {
      position: relative;
      display: inline-block;
    }
    
    .tooltip .tooltiptext {
      visibility: hidden;
      width: 140px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      top: -65%;
    }

    .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      right: 85%;
      margin-left: 45px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
    
    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }
`