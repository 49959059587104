import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Notification, Spinner } from 'ur-majorelle'

import AnswersList from './answersList'
import Editor from '../Editor'

import { StyleBase, Suggestion, Button, Footer, Warning } from './style'

// warnings
const NO_WARNING = 0
const ANSWER_NOT_SELECTED = 1
const QUESTION_SKIPPED = 2


class Question extends Component {
  state = {
    selectedAnswer: null,
    warning: NO_WARNING,
    buttonDisabled: false,
    requestFailed: false
  }

  handleSelectAnswer = (e) => {
    const { buttonDisabled, warning } = this.state
    // disable send multiple answers
    if (buttonDisabled)
      return
    const selectedAnswer = e.target.value
    const newState = { selectedAnswer }
    if (warning === ANSWER_NOT_SELECTED) {
      newState.warning = NO_WARNING
    }

    this.setState(newState)
  }

  // HACK
  // TODO: refactor this.
  handleSkip = (e) => {
    this.setState({ warning: QUESTION_SKIPPED, selectedAnswer: null }, () => {
      this.handleSubmitAnswer(e)
    })
  }

  handleCloseAlert = () => {
    this.setState({
      requestFailed: false
    })
  }

  handleSubmitAnswer = async (e) => {
    try {
      const { selectedAnswer, warning } = this.state
      const { question: { _id }, onSubmit } = this.props

      // If no answer has been selected and question is not skipped
      if (!selectedAnswer && warning !== QUESTION_SKIPPED) {
        this.setState({ warning: ANSWER_NOT_SELECTED }, () => {
          window.scrollTo(0, 0)
        })
        return
      }
      this.setState({ 
        buttonDisabled: true, 
      })
      window.heap.addEventProperties({
        Question: _id,
        Answer: selectedAnswer,
      })
      const submitted = await onSubmit(selectedAnswer || 'I don\'t know')
      // disable deselect answer after click on confirm button
      if (submitted) {
        this.setState({ 
          selectedAnswer: null, 
          warning: NO_WARNING, 
          buttonDisabled: false, 
          requestFailed: false 
        })
      } else {
        this.setState({ 
          buttonDisabled: false, 
          requestFailed: true 
        })
      }
    } catch(e) {
      console.log('---------------------------------------------------------------------')
      console.log(e)
      console.log('---------------------------------------------------------------------')
      this.setState({ 
        buttonDisabled: false, 
        requestFailed: true 
      })
    }
  }

  render() {
    const { selectedAnswer, warning, buttonDisabled, requestFailed } = this.state
    const { question, quizName, questionNbr, totalQuestions } = this.props
    return (
      <StyleBase>
        <Notification
          isVisible={requestFailed}
          type="warning"
          title="Failed sending your answer"
          message="A connectivity issue has occcured, please submit your answer again"
          position="topCenter"
          onClose={() => this.setState({ requestFailed: false, buttonDisabled: false })}
        />
        <header>
          <h4>## {quizName}</h4>
          { 
            warning === ANSWER_NOT_SELECTED
            && (
              <Warning ref={this.warningRef}>
                <div>
                  <svg width="18" height="17" xmlns="http://www.w3.org/2000/svg"><path d="M10.296 13.811v-1.908a.32.32 0 0 0-.096-.236.309.309 0 0 0-.226-.096H8.046a.309.309 0 0 0-.226.096.32.32 0 0 0-.096.236v1.908a.32.32 0 0 0 .096.236.309.309 0 0 0 .226.096h1.928a.309.309 0 0 0 .226-.096.32.32 0 0 0 .096-.236zm-.02-3.756l.18-4.61a.221.221 0 0 0-.1-.192c-.087-.073-.167-.11-.24-.11h-2.21c-.074 0-.155.037-.242.11-.067.047-.1.118-.1.211l.17 4.59c0 .068.034.123.101.166a.433.433 0 0 0 .241.066h1.858a.408.408 0 0 0 .236-.066.223.223 0 0 0 .106-.165zm-.14-9.382l7.713 14.143c.235.422.228.844-.02 1.265-.113.195-.27.349-.467.463-.197.113-.41.17-.638.17H1.296c-.228 0-.44-.057-.638-.17a1.262 1.262 0 0 1-.467-.463c-.248-.421-.254-.843-.02-1.265L7.885.673A1.283 1.283 0 0 1 9.01 0a1.283 1.283 0 0 1 1.125.673z" fill="#D5DADE" fillRule="evenodd" /></svg>
                  <span>You didn't select an answer, do you want to skip this one?</span>
                </div>
                <Button btnStyle="primary" onClick={this.handleSkip}>
                  Skip
                </Button>
              </Warning>
            )
          }
          <h3 className="title"># Question {`${questionNbr}/${totalQuestions}`}</h3>
          <h3 className="question">{question.body}</h3>
          {
            question.snippet && question.snippet.value && (
              <Editor value={question.snippet.value} mode={question.snippet.mode} />
            )
          }
        </header>
        <Suggestion>
          <h3># Select the correct answer:</h3>
          <AnswersList
            selectedAnswer={selectedAnswer}
            handleSelectAnswer={this.handleSelectAnswer}
            requestSent={buttonDisabled}
            answers={question.answers} />
        </Suggestion>

        <Footer>
          <Button btnStyle="secondary" onClick={this.handleSkip} disabled={buttonDisabled}>
            {
              (buttonDisabled && warning === QUESTION_SKIPPED) ? 
              <div className="button-content">
                <Spinner size="small" />
                <span></span>
                <span>Skip</span>
              </div> :
              "Skip"
            }
          </Button>
          <Button className="confirm-button" btnStyle="primary" onClick={this.handleSubmitAnswer} disabled={buttonDisabled}>
            {
              (buttonDisabled && warning !== QUESTION_SKIPPED) ? 
              <div className="button-content">
                <Spinner size="small" color="white" />
                <span></span>
                <span>Confirm</span>
              </div> :
              "Confirm"
            }
          </Button>
        </Footer>
      </StyleBase>
    )
  }
}

Question.propTypes = {
  quizName: PropTypes.string.isRequired,
  question: PropTypes.shape({
    body: PropTypes.string,
    snippet: PropTypes.any,
    answers: PropTypes.array,
  }),
  questionNbr: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default Question
