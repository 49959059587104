import React from 'react'

import { StyleBase } from './style'

const Checkbox = ({ onChange, onClick, disabled, label, ...props }) => {
  const checkboxProps = {
    disabled,
    onChange: e => {
      if (!disabled) {
        onChange(e)
      }
    },
    onClick: e => {
      if (!disabled) {
        onClick(e)
      }
    },
  }
  return (
    <StyleBase> {label}
      <input {...props} {...checkboxProps} type="checkbox" />
      <span className="checkmark" />
    </StyleBase>
  )
}

Checkbox.defaultProps = {
  onChange: () => {},
  onClick: () => {},
}

export default Checkbox
