import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import * as Sentry from '@sentry/browser'

const cache = new InMemoryCache()

export default new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        )
      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        if (process.env.NODE_ENV === 'production') {
          Sentry.captureException(networkError)
        }
      }
    }),
    new HttpLink({
      uri: process.env.REACT_APP_API_URL
    })
  ]),
  cache
})
