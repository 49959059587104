import styled from "styled-components"

export const StyleBase = styled.div`
  overflow: hidden;
  header {
    margin: 100px 0 70px;
    h1 {
      font-size: 26px;
      font-family: "Lucida Bold", sans-serif;
      margin: 50px 0 15px;
      color: #fff;
      line-height: 1.8;
      @media (max-width: 640px) {
        font-size: 20px;
      }
    }
    h3 {
      font-family: "Lucida Bold", sans-serif;
    }
    p {
      max-width: 450px;
      line-height: 1.555;
      margin: 30px 0;
      @media (max-width: 640px) {
        max-width: 315px;
      }
    }
  }
`

export const TopBar = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 200px;
    @media (max-width: 640px) {
      width: 100px;
      height: 37px;
    }
  }
  .hiring {
    width: 118px;
    height: 30px;
    display: flex;
    background: rgba(96, 80, 220, 0.5);
    border: 1px solid #6050dc;
    box-sizing: border-box;
    border-radius: 30px;
    img {
      width: 16px;
      height: 10px;
      margin: 9px -1px 10px 9px;
    }
    a {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 7px 10px 7px 6px;
      color: #ffffff;
      text-decoration: none;
    }
  }
`

export const ScoreStyle = styled.span`
  font-size: 40px;
  color: #6050dc;
  line-height: 0;
  @media (max-width: 640px) {
    font-size: 36px;
  }
`

export const Info = styled.p`
  background-color: rgba(96, 80, 220, 0.2);
  padding: 10px 20px;
  border-left: 4px solid #6050dc;
  font-size: 14px;
  a {
    color: #6050dc;
  }
`
export const ShareContainer = styled.div`
  background: #ffffff;
  border-radius: 4px;
  max-width: 590px;
  height: 133px;
  padding: 20px 35px;
  color: #1a1a1a;
  font-size: 14px;
  font-family: "Roboto";
  text-align: center;
  line-height: 160%;
  @media (max-width: 640px) {
    max-width: 315px;
    min-height: 209px;
  }

  a {
    color: #6050dc;
    font-weight: bold;
    font-size: 15px;
  }
  .share-title {
    font-weight: bold;
  }
  .share-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    @media (max-width: 640px) {
      flex-wrap: wrap;
    }
    .share-btn {
      background: #fff;
      font-size: 16px;
      line-height: 137%;
      border-radius: 4px;
      width: 87px;
      border: 1px solid #fff;
      height: 30px;
      display: flex;
      transition: all 0.1s ease-in-out;
      &:hover {
        cursor: pointer;
        border-color: #e5e5e5;
      }
      @media (max-width: 640px) {
        margin-bottom: 20px;
      }
      p {
        margin: 4px 10px 4px 0px;
        text-align: right;
      }
      img {
        margin: 0px 10px;
        line-height: none;
      }
    }
  }
`
