import React, { Component } from 'react'

import ProgressRing from '../ProgressRing'
import {
  TopicScoresRow,
  TopicsBody
} from './style'


class Topics extends Component {
  state = {
    expanded: true
  }

  render() {
    const { expanded } = this.state
    const { topics } = this.props

    return (
      <TopicScoresRow>
        <TopicsBody>
          {expanded &&
            (<>
              <h4>Score By topic {(!topics.length) && (<span style={{ color: "#808080" }}> - Topics not found</span>)}</h4>
              <div className="metrics">
                {
                  topics.map(topic => (
                    <div key={topic.id}>
                      <ProgressRing radius={33} stroke={7} progress={topic.score} />
                      <div className="text tooltip">
                        <h4>{topic.topicName.split(' ')[0]}</h4>
                        {
                          topic.topicName.split(' ').length > 1 && (
                            <span className="tooltiptext">{topic.topicName}</span>
                          )
                        }
                        <h3>{topic.score}%</h3>
                      </div>
                    </div>
                  ))
                }
              </div>
            </>)
          }
        </TopicsBody>
      </TopicScoresRow >
    )
  }
}

export default Topics
