import styled from 'styled-components'

export const NoQuizStyle = styled.div`
  text-align: center;
  margin-top: 150px;
  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    margin: 20px 0 40px;
  }
  p {
    line-height: 24px;
    a {
      color: #6050DC;
    }
  }
`
