import React from 'react'
import styled from 'styled-components'

const StyleBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  div.spinner {
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 4px solid rgba(98, 94, 211, .2);
    border-right-color: #625ed3;
    border-radius: 50%;
  }

@keyframes rotate {
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(360deg); }
}
`

export const Loading = () => {
  return (
    <StyleBase>
      <div className="spinner" />
    </StyleBase>
  )
}

export default Loading
