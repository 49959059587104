import React from 'react'

const AnswersList = ({ answers, handleSelectAnswer, requestSent, selectedAnswer }) => {
  return (
    <div className="answers">
      {answers.map((x, i) => (
        <div key={i} className="group-answer">
          <input
            id={`answer-${i}`}
            type="radio"
            name="answer"
            onChange={handleSelectAnswer}
            value={x}
            disabled={requestSent}
            checked={x === selectedAnswer} />
          <label htmlFor={`answer-${i}`}>{x}</label>
          <div className="radio" />
        </div>
      ))}
    </div>
  )
}

export default AnswersList
