import styled from 'styled-components'

export const StyleBase = styled.label`
  display: block;
  position: relative;
  user-select: none;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  box-sizing: border-box;
  color: #BDBDBD;
  font-size: 13px;
  line-height: 19px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 2px solid #6050DC;
  }
  input:checked ~ .checkmark {
    background-color: #6050DC;
    border-color: #6050DC;
  }
  .checkmark:after, .checkmark:before {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after,
  input:checked ~ .checkmark:before {
    display: block;
  }
  .checkmark:after {
    width: 2.5px;
    height: 10px;
    transform: rotate(45deg);
    border-radius: 1.68px;
    background-color: #FFF;
    transform-origin: 1px 16px;
  }
  .checkmark:before {
    width: 2.5px;
    height: 6px;
    border-radius: 1.68px;
    background-color: #FFF;
    transform: rotate(-45deg);
    transform-origin: 11px 2px;
  }
`
