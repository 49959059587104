import React from 'react'

import { NoQuizStyle } from './style'
import Wrapper from '../Wrapper'
import Logo from '../Logo'

const NoQuizId = () => (
  <Wrapper>
    <NoQuizStyle>
      <header>
        <Logo />
        <h1>Quiz not found</h1>
        <p>Sorry, we can't find the requested quiz.</p>
        <p>
          Click <a href='https://www.gemography.com/'>here</a> to see our
          current opening jobs{' '}
        </p>
      </header>
    </NoQuizStyle>
  </Wrapper>
)

export default NoQuizId
