import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { Modal } from 'ur-majorelle'

import { GET_SECRET_SUBMISSION } from '../actions/index'

import Wrapper from '../Components/Wrapper'
import Editor from '../Components/Editor'
import Logo from '../Components/Logo'
import Loading from '../Components/Loading'
import ProgressRing from '../Components/ProgressRing'

import { StyleBase, Footer, Button } from '../Components/Question/style'
import { AnswersList, QuizInfo, Stats, Header, QuestionCounter } from './style'
import Topics from '../Components/SubScores'

const SubmissionShare = props => {
  const { data: { error, sharedSubmission, loading } = {} } = props

  if (loading) {
    return (
      <div style={{ height: '100vh' }}>
        <Loading />
      </div>
    )
  }
  if (error) {
    return <Redirect to="/" />
  }

  const { quiz, quizName, score } = sharedSubmission
  const { questions: quizQuestions } = quiz
  const [currQuestionIndex, setCurrQuestionIndex] = useState(0)
  const [showSubScore, setShowSubScore] = useState(false)
  const question = quizQuestions[currQuestionIndex].question

  const handleModalClose = () => {
    setShowSubScore(false)
  }

  const handleModalOpen = () => {
    setShowSubScore(true)
  }

  let candidateAnswer
  let correctAnswerIndex = -1
  let wrongAnswerIndex = -1

  candidateAnswer = sharedSubmission.answers[currQuestionIndex].answer
  correctAnswerIndex = question.answers.indexOf(question.answer) + 1
  const isAnswerCorrect = candidateAnswer === question.answer
  wrongAnswerIndex = !isAnswerCorrect
    ? question.answers.indexOf(candidateAnswer) + 1
    : wrongAnswerIndex
  const isEmptyAnswer =
    (candidateAnswer !== undefined && candidateAnswer.length === 0) ||
    candidateAnswer === "I don't know"
  return (
    <Wrapper>
      <Header>
        <Logo />
        <span>
          <span className="italic">{sharedSubmission.candidate.name.split(' ').length > 0 ? sharedSubmission.candidate.name.split(' ')[0] : sharedSubmission.candidate.name}</span> has
          scored <span className="score" onClick={handleModalOpen}>{score}%</span>
        </span>
        {
          showSubScore && (
            <Modal width={616} onClose={handleModalClose}>
              <Topics topics={sharedSubmission.subScores} />
            </Modal>
          )
        }
      </Header>
      <QuizInfo>
        <div>
          <h4>## {quizName}</h4>
        </div>
        <Stats>
          <div className="nbr-submissions">
            <span>Total Submissions</span>
            <h4>{quiz.totalSubmission}</h4>
          </div>
          <div className="avg-score">
            <span>Avg. quiz score</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4>{quiz.avgScore || '-'}%</h4>
              <ProgressRing radius={50} stroke={8} progress={quiz.avgScore} />
            </div>
          </div>
        </Stats>
      </QuizInfo>
      <StyleBase>
        <header>
          <QuestionCounter className="title" isEmptyAnswer={isEmptyAnswer}># Question {currQuestionIndex + 1} / {quizQuestions.length}</QuestionCounter>
          <h3 className="question">{question.body}</h3>
          {question.snippet && question.snippet.value && (
            <Editor
              value={question.snippet.value}
              mode={question.snippet.mode}
            />
          )}
        </header>

        <AnswersList
          isEmptyAnswer={isEmptyAnswer}
          wrongAnswerIndex={wrongAnswerIndex}
          correctAnswerIndex={correctAnswerIndex}
        >
          {question.answers.map((x, answerIndex) => (
            <li key={answerIndex}>
              {' '}
              <span>{x}</span>
            </li>
          ))}
        </AnswersList>

        <Footer>
          <Button
            disabled={currQuestionIndex === 0}
            btnStyle="secondary"
            onClick={() => setCurrQuestionIndex(currQuestionIndex - 1)}
          >
            Prev
          </Button>
          <Button
            btnStyle="primary"
            disabled={currQuestionIndex === quizQuestions.length - 1}
            onClick={() => setCurrQuestionIndex(currQuestionIndex + 1)}
          >
            Next
          </Button>
        </Footer>
      </StyleBase>
    </Wrapper>
  )
}

export default graphql(GET_SECRET_SUBMISSION, {
  options: props => {
    return {
      variables: { token: props.match.params.id }
    }
  }
})(SubmissionShare)
