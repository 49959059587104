import React, { Component } from 'react'
import styled from 'styled-components'

const Timer = styled.div`
  border-radius: 2px;
  color: #fff;
  max-width: 170px;
  width: 100%;
  margin: 0 auto 30px;
  padding: 10px 15px;
  font-family: 'Avenir Light', sans-serif;
  text-align: center;
  font-size: 15px;
  background: ${props => props.background};
  h3 { margin: 0 }
`

class CountDown extends Component {
  constructor(props) {
    super(props)
    const { timeLeft } = this.props
    const time = this.secondsToTime(timeLeft)
    this.state = {
      time,
      timeLeft
    }
    this.countDown = this.countDown.bind(this)
  }

  componentDidMount() {
    this.timer = setInterval(this.countDown, 1000)
  }

  secondsToTime(secs) {
    const minute = Math.floor(secs / 60)
    const second = secs - (minute * 60)

    return { minute, second }
  }

  componentWillReceiveProps(props) {
    this.setState({ timeLeft: props.timeLeft })
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  countDown() {
    const { answerSent } = this.props
    if (answerSent)
      return
    const timeLeft = this.state.timeLeft - 1
    const { updateCompletionTime } = this.props
    if (updateCompletionTime) updateCompletionTime(this.props.duration - timeLeft)
    this.setState({
      time: this.secondsToTime(timeLeft),
      timeLeft
    })

    if (timeLeft === 0) {
      clearInterval(this.timer)
      this.props.onQuestionTimeOut()
    }
  }


  render() {
    const { time: { minute, second }, timeLeft } = this.state
    const progress = (this.props.duration - timeLeft) / this.props.duration

    return (
      <Timer background={
        timeLeft > 10
          ? `linear-gradient(90deg, #615ad6 ${progress * 100}%, rgb(97, 90, 214, 0.3) 0%)`
          : `linear-gradient(90deg, #bd3835 ${progress * 100}%, rgba(142, 51, 49, 0.3) 0%)`
      }>
        <h3>{minute} min {`0${second}`.slice(-2)} sec</h3>
      </Timer>
    )
  }
}

export default CountDown
