import styled from 'styled-components'

export const StyleBase = styled.div`
  margin-top: 10em;
  h1 {
    font-family: 'Lucida Bold', sans-serif;
    font-size: 23px;
    letter-spacing: -.5px;
    margin-bottom: 0;
  }
  p {
    margin: 20px 0;
  }
  button {
    background-color: #615ad6;
    width: 100%;
    height: 40px;
    border: 0;
    border-radius: 2px;
    color: #fff;
    font-size: 16px;
    font-family: 'Avenir Medium', sans-serif;
    cursor: pointer;
    transition: .15s all;
    max-width: 150px;
    &:hover {
      transform: translateY(-1px)
    }
  }
`
