import React from 'react'

import { StyleBase } from './style'

const TimeOut = ({ onResumeQuiz }) => {
  return (
    <StyleBase>
      <h1>Be careful, you ran out of time there.</h1>
      <p>No worries, but just make sure to keep an eye on the timer in the top.</p>
      <button onClick={onResumeQuiz}>Continue</button>
    </StyleBase>
  )
}

export default TimeOut
