import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { ApolloProvider } from 'react-apollo'
import App from './app'
import SubmissionShare from './SubmissionShare'
import client from './apollo'
import { createGlobalStyle } from 'styled-components'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://d78e732be4264335a18ef25377788cf7@sentry.io/1262804'
  })
}

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Montserrat:600,700|Roboto:400,500,700');

  * {
    box-sizing: border-box;
  }
  body {
    background-color: #1A1A1A;
    color: #D5DADE;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
    margin: 0;
  }
`

ReactDOM.render(
  <>
    <GlobalStyle />
    <ApolloProvider client={client}>
      <Router>
        <Route path='/' exact component={App} />
        <Route path="/submission-share/:id" component={SubmissionShare} />
      </Router>
    </ApolloProvider>
  </>,
  document.getElementById('root')
)
