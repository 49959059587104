import React from 'react'
import styled from 'styled-components'

import Logo from '../Logo'

const StyleBase = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 15px;
  h4 {
    font-size: 16px;
    color: #D5DADE;
  }
` 
const Header = ({ name = 'Captain Anounymous' }) => {
  return (
    <StyleBase>
      <Logo />
      <h4>{name}</h4>
    </StyleBase>
  )
}

export default Header
