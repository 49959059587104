import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import * as Sentry from '@sentry/browser'
import qs from 'qs'

import { GET_QUIZ } from './actions'
import QuizNotFound from './Components/QuizNotFound'

import Welcome from './Welcome'
import PrivateQuiz from './Private'
import PublicQuiz from './Public'

const quizId = () => {
  const query = window.location.search.split('?')[1]
  const { qid } = qs.parse(query)
  return qid
}

class App extends Component {
  state = {
    readyToQuiz: false,
    submission: null,
    email: null
  }

  handleStartQuiz = (submission = null,email = null) => {
    this.setState({ readyToQuiz: true, submission, email })
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setUser({
          email:this.state.email
        })
        scope.setExtras(errorInfo)
        Sentry.captureException(error)
      })
    }
  }

  render() {
    const { data: { error, quiz, loading } = {} } = this.props
    const { readyToQuiz, submission } = this.state
    if (loading) {
      return null
    }
    if (
      (error && error.message === 'GraphQL error: quiz not found') ||
      // error ||
      !quizId()
    ) {
      return <QuizNotFound />
    }
    return (
      <>
        <div
          className="fb-customerchat"
          attribution="setup_tool"
          page_id="741986369322881"
          theme_color="#6050DC"
        />
        {!readyToQuiz ? (
          <Welcome
            handleStartQuiz={this.handleStartQuiz}
            quizType={quiz ? quiz.type : 'private'}
            quizName={quiz ? quiz.name : null}
            quizImage={quiz ? quiz.image : null}
            questionsNbr={quiz && quiz.questions ? quiz.questions.length : null }
          />
        ) : quiz && quiz.type === 'anon' ? (
          <PublicQuiz quiz={quiz} />
        ) : (
          <PrivateQuiz {...submission} />
        )}
      </>
    )
  }
}

export default graphql(GET_QUIZ, {
  options: _ => {
    return {
      variables: { _id: quizId() }
    }
  },
  skip: _ => !quizId()
})(App)
