import styled, { css } from 'styled-components'

export const AnswersList = styled.ul`
  padding: 0;
  list-style: none;
  li {
    font-size: 14px;
    margin: 10px 0;
    padding: 20px 30px;
    border-radius: 4px;
    display: flex;
    @media screen and (max-width: 520px) {
      flex-direction: column;
      padding: 20px 15px;
    }
    span {
      &:first-child {
        margin-right: 50px;
      }
    }
  }
  ${props =>
    !props.isEmptyAnswer &&
    props.correctAnswerIndex > 0 &&
    css`
      li:nth-child(${props.correctAnswerIndex}) {
        background-color: #45cb5e;
        span {
          color: #fff;
        }
      }
    `}
  ${props =>
    props.wrongAnswerIndex > 0 &&
    css`
      li:nth-child(${props.wrongAnswerIndex}) {
        background-color: #e03b47;
        span {
          color: #fff;
        }
      }
    `}
`

export const Stats = styled.div`
  display: flex;
  text-align: center;
  /* margin-right: 50px; */
  span {
    color: #909090;
    font-size: 12px;
  }
  .nbr-submissions span,
  .avg-score span {
    display: block;
  }
  .avg-score {
    position: relative;
    margin-left: 40px;
    h4 {
      color: #d5dade;
      font-size: 16px;
      position: absolute;
      left: 51%;
      transform: translateX(-50%);
    }
  }
  .nbr-submissions h4 {
    color: #d5dade;
    font-size: 26px;
    margin-top: 34px;
  }
`

export const QuizInfo = styled.div`
  padding-top: 70px;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media screen and (max-width: 590px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
  }
  > div {
    > h4 {
      padding-bottom: 20px;
      font-size: 24px;
      margin: 0;
    }
  }
  border-bottom: 4px solid #d8d8d8;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 15px;
  @media screen and (max-width: 590px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    > span {
      margin-top: 30px;
    }
  }
  span.score {
    font-size: 26px;
    font-weight: 600;
    padding-left: 5px;
    color: #6050DC; 
    text-decoration: underline; 
    cursor: pointer
  }
  h4 {
    font-size: 16px;
    color: #d5dade;
  }
`

export const QuestionCounter = styled.h3`
  ${props =>
    props.isEmptyAnswer &&
    css`
      &:after {
        content: 'Not answered';
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 0;
        background: #e8b766;
        border-radius: 3px;
        padding: 7px 20px;
        margin-left: 20px;
        @media screen and (max-width: 420px) {
          margin-left: 0px;
          margin-top: 10px;
          display: block;
        }
      }
    `}
`
