import styled, { css } from 'styled-components'

export const StyleBase = styled.main`
  header {
    h4 {
      border-bottom: 4px solid #D8D8D8;
      padding-bottom: 20px;
      font-size: 24px;
      margin: 0; 
    }
    .title {
      color: #D5DADE;
      margin: 50px 0 20px;
      font-size: 20px;
      font-weight: 700;
    }
    h3.question {
      color: #BDBDBD;
      margin: 0;
      font-size: 16px;
    }
  }
`

export const Suggestion = styled.div`
  h3 {
    color: #D5DADE;
    margin: 50px 0 30px;
    font-size: 20px;
    font-weight: 700;
  }
  .answers {
    .group-answer {
      display: flex;
      position: relative;
      label {
        cursor: pointer;
        color: #BDBDBD;
        padding: 20px 0 20px 80px;
        width: 100%;
        &:hover {
          background-color: rgba(213, 218, 222, 0.1);
        }
      }
      input[type="radio"] {
        opacity: 0;
        cursor: pointer;
        margin: 0;
        position: absolute;
      }
      .radio {
        &::before, &::after {
          content: '';
          position: absolute;
          left: 28px;
          top: 48%;
          display: block;
          height: 2px;
          width: 10px;
          background: #525252;
          transform-origin: 9px;
        }
        &::before {
          transform: rotate(35deg);
        }
        &::after {
          transform: rotate(-35deg);
        }
      }
      input[type="radio"]:checked + label,
      input[type="radio"]:focus + label {
        background-color: #3f3f46;
        color: #cccccc;
      }
      input[type="radio"]:checked ~ .radio,
      input[type="radio"]:focus ~ .radio {
        &::before, &::after {
          background: #6050DC;
        }
      }
    }
  }
`

const buttonStyles = {
  primary: css`
  background-color: #6050DC;
  color: #fff;
  `,
  secondary: css`
    background-color: #fff;
    color: #6050DC;
  `
}

export const Button = styled.button`  
  ${({ btnStyle }) => buttonStyles[btnStyle]};
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
  transition: .15s all;
  max-width: 250px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  &:hover {
    transform: translateY(-1px)
  }
  &:disabled {
    opacity: .5;
    pointer-events: none;
    cursor: not-allowed;
  }
`

export const Footer = styled.div`
  display: flex;
  max-width: 560px;
  justify-content: space-around;
  flex-flow: row wrap;
  margin: 70px auto;
  @media screen and (max-width: 590px) {
    margin: 40px auto;
    max-width: 260px;
    button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;

    span:first-of-type {
      width: 5%;
    }
  }
`

export const Warning = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 20px;
  background: rgb(97, 90, 214, 0.3);
  border: 1px solid #6050DC;
  border-radius: 4px;
  padding: 15px 30px;
  color: #D5DADE;
  background-color: rgba(96,80,220,0.05);
  div {
    svg {
      margin-right: 30px;
      position: relative;
      top: 2px;
    }
  }
  button {
    max-width: 128px;
  }
`
